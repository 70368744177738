.video-placeholder {
     background-color: deepskyblue;
     height: 50vh;
}

.take-action-card {
    margin-top: 30px;
    min-width: 350px;
    flex-grow: 1;
}

.btn.btn-social-icon {
    width: 50px;
    height: 50px;
    padding: 0;
    color: white;
    font-size: 1.25rem;
}

.btn-rounded {
    border-radius: 50px;
    background-color: transparent;
    border-color: white;
}

.col--full-height {
    display: flex;
}

.take-action-card .card-text {
    flex-grow: 1;
}
